import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { GoogleLogin } from '@react-oauth/google';
import { AuthContext } from '../../context/AuthContext';
import { login } from '../../services/AuthService';
import CampaignList from './CampaignList';

const Home: React.FC = () => {
  const { isAuthenticated, setIsAuthenticated, requireAuth } = useContext(AuthContext)!;
  const navigate = useNavigate(); 

  const handleCreateCampaign = () => {
    requireAuth(() => {
      navigate('/create-campaign'); 
    });
  };

  const handleLoginSuccess = async (tokenResponse: any) => {
    try {
      await login(tokenResponse.credential);
      setIsAuthenticated(true);
      console.log('Login bem-sucedido');
    } catch (error) {
      console.error('Erro durante o login:', error);
    }
  };

  return (
    <div className="home-page">
      <h1>Cobre seu Político!</h1>
      {isAuthenticated ? (
        <button onClick={handleCreateCampaign}>Criar Nova Campanha</button>
      ) : (
        <div>
          <p>Para criar uma campanha, você precisa estar logado.</p>
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={() => console.error('Falha no login')}
          />
          <hr />
        </div>
      )}

      <CampaignList />
    </div>
  );
};

export default Home;
