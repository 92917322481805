import { getAuthToken, getRefreshToken, setAuthToken, setRefreshToken, setUser, removeTokens, getTokenExpiry } from './TokenService';

export const refreshAuthToken = async (): Promise<string | null> => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) return null;

  try {
    const response = await fetch('/api/auth/google/refresh', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken }),
    });

    const data = await response.json();
    if (data.token) {
      setAuthToken(data.token);
      return data.token;
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
  }
  return null;
};

export const login = async (googleToken: string) => {
  try {
    const response = await fetch('/api/auth/google', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: googleToken }),
    });

    const data = await response.json();

    if (data.token && data.refreshToken) {
      setAuthToken(data.token);
      setRefreshToken(data.refreshToken);
      setUser(JSON.stringify(data.user));
      console.log('Token e refreshToken armazenados com sucesso');
    } else {
      throw new Error('Falha ao receber JWT ou refresh token');
    }
  } catch (error) {
    console.error('Erro durante o login:', error);
    throw error;
  }
};

export const logoutUser = () => {
  removeTokens();
  window.location.href = '/';
};

export const handleProtectedRequest = async (): Promise<any> => {
  let authToken = getAuthToken();
  const tokenExpiry = getTokenExpiry(authToken);
  
  if (!authToken || !tokenExpiry) return null;

  const currentTime = Math.floor(Date.now() / 1000);

  if (currentTime >= tokenExpiry / 1000 - 60) {
    const newToken = await refreshAuthToken();
    if (newToken) {
      authToken = newToken;
    } else {
      console.error('Failed to refresh token, logging out user.');
      removeTokens();
      window.location.href = '/login';
      return null;
    }
  }

  try {
    const response = await fetch('/api/protected', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      console.error('Failed to fetch protected data');
      return null;
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};
