import { getAuthToken } from './TokenService';

export const getCampaigns = async () => {
  const response = await fetch('/api/public/campaigns');
  return await response.json();
};

export const getCampaignById = async (id: string) => {
  const response = await fetch(`/api/public/campaigns/${id}`);
  if (!response.ok) {
    throw new Error('Failed to fetch the campaign');
  }
  return await response.json();
};

export const getCampaignBySlug = async (slug: string) => {
  const response = await fetch(`/api/public/campaigns/slugs/${slug}`);
  if (!response.ok) {
    throw new Error('Failed to fetch the campaign details');
  }
  return await response.json();
};
  
export const createCampaign = async (title: string, description: string) => {
  const authToken = getAuthToken();

  const response = await fetch('/api/protected/campaigns', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    body: JSON.stringify({ title, description }),
  });

  if (!response.ok) {
    throw new Error('Failed to create the campaign');
  }

  return await response.json();
};
