import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { createCampaign } from '../../services/CampaignService';
import { AuthContext } from '../../context/AuthContext';
import { GoogleLogin } from '@react-oauth/google';
import { login } from '../../services/AuthService';

const CreateCampaign: React.FC = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const { isAuthenticated, requireAuth, setIsAuthenticated } = useContext(AuthContext)!;
  const navigate = useNavigate();

  const handleLoginSuccess = async (tokenResponse: any) => {
    try {
      await login(tokenResponse.credential);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Falha no login', error);
    }
  };

  const handleCreateCampaign = async () => {
    try {
      const campaign = await createCampaign(title, description);
      navigate(`/campaign/${campaign.slug}`, { state: { id: campaign.id } });
    } catch (error) {
      console.error('Error ao criar campanha:', error);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    requireAuth(() => {
      handleCreateCampaign();
    });
  };

  return (
    <div className="create-campaign">
      <button onClick={() => navigate(-1)}>Voltar</button>
      <h1>Criar uma Campanha</h1>
      {isAuthenticated ? (
        <form onSubmit={handleSubmit}>
          <div>
            <label>Título:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Descrição:</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            ></textarea>
          </div>
          <button type="submit">Criar</button>
        </form>
      ) : (
        <div>
          <p>Você precisa estar autenticado para criar uma campanha.</p>
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={() => console.error('Falha no login')}
          />
        </div>
      )}
    </div>
  );
};

export default CreateCampaign;
