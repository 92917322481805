import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/public/Home';
import CreateCampaign from './components/protected/CreateCampaign';
import CampaignDetail from './components/public/CampaignDetail';
import Footer from './components/public/Footer';

const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route
          path="/create-campaign"
          element={<CreateCampaign />}
        />
        <Route path="/campaign/:slug" element={<CampaignDetail />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
