import React, { createContext, useState, useEffect, useRef, ReactNode } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { getAuthToken, getRefreshToken, checkTokenExpiry, refreshAuthToken } from '../services/TokenService';

export type AuthContextType = {
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  requireAuth: (callback: () => void) => void;
};

export const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const callbackAfterLogin = useRef<(() => void) | null>(null);

  useEffect(() => {
    const authToken = getAuthToken();
    const refreshToken = getRefreshToken();

    const checkAuthState = async () => {
      if (authToken && checkTokenExpiry(authToken)) {
        setIsAuthenticated(true);
        callbackAfterLogin.current?.();
        callbackAfterLogin.current = null;
      } else if (refreshToken && checkTokenExpiry(refreshToken)) {
        const newAuthToken = await refreshAuthToken();
        if (newAuthToken) {
          setIsAuthenticated(true);
          callbackAfterLogin.current?.();
          callbackAfterLogin.current = null;
        } else {
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuthState();
  }, []);

  const requireAuth = (callback: () => void) => {
    if (isAuthenticated) {
      callback();
    } else {
      callbackAfterLogin.current = callback;
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, requireAuth }}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
        {children}
      </GoogleOAuthProvider>
    </AuthContext.Provider>
  );
};
