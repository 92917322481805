import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { login } from '../../services/AuthService';
import { AuthContext } from '../../context/AuthContext';
import { getCampaignBySlug } from '../../services/CampaignService';

const CampaignDetail: React.FC = () => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext)!;
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const [campaign, setCampaign] = useState<any>(null);

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        if (slug) {
          const campaignData = await getCampaignBySlug(slug);
          setCampaign(campaignData);
        } else {
          console.error('Campanha não encontrada');
        }
      } catch (error) {
        console.error('Erro ao buscar a campanha:', error);
      }
    };

    fetchCampaign();
  }, [slug]);

  const handleLoginSuccess = async (tokenResponse: any) => {
    try {
      await login(tokenResponse.credential);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Falha no login', error);
    }
  };

  if (!campaign) {
    return <p>Carregando...</p>;
  }

  return (
    <div className="campaign-detail">
      <button onClick={() => navigate(-1)}>Voltar</button>

      <h2>{campaign.title}</h2>
      <p>{campaign.description}</p>
      <p>Criado por: {campaign.user.name}</p>
      <p>Criado em: {new Date(campaign.createdAt).toLocaleString()}</p>
      <hr />
      {isAuthenticated ? (
        <button>Cobrar!</button>
      ) : (
        <div>
          <p>Para cobrar uma campanha, você precisa estar logado.</p>
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={() => console.error('Falha no login')}
          />
        </div>
      )}
    </div>
  );
};

export default CampaignDetail;
