import React, { useContext } from 'react';
import '../../styles/Footer.css';
import { AuthContext } from '../../context/AuthContext';
import { logoutUser } from '../../services/AuthService';

const Footer: React.FC = () => {
    const { isAuthenticated } = useContext(AuthContext)!;

  return (
    <footer>
      {isAuthenticated && (
        <button onClick={logoutUser}>Logout</button>
      )}
    </footer>
  );
};

export default Footer;
