import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCampaigns } from '../../services/CampaignService';

const CampaignList: React.FC = () => {
  const [campaigns, setCampaigns] = useState<any[]>([]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      const data = await getCampaigns();
      setCampaigns(data);
    };
    fetchCampaigns();
  }, []);

  return (
    <div className="campaign-list">
      <h2>Campanhas</h2>
      {campaigns.length === 0 ? (
        <p>Não há campanhas disponíveis</p>
      ) : (
        <ul>
          {campaigns.map((campaign) => (
            <li key={campaign.id}>
              <Link to={`/campaign/${campaign.slug}`}>
                {campaign.title}
              </Link>
              <p>{campaign.description}</p>
              <p>Criado por: {campaign.user.name}</p>
              <p>{campaign.createdAt}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CampaignList;
